@use "colors";
@use "loadingSpinner";

.main {
  min-width: 350px;
  height: 100vh;
  overflow: auto;
  font-size: calc(10px + 2vmin);
}

.fancy-text {
  font-family: "Tangerine", cursive, Arial, Helvetica, sans-serif !important;
}

.brand-text {
  font-family: "Caudex", serif, Arial, Helvetica, sans-serif !important;
}

.form-group {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

label {
  font-size: 18px;
  font-weight: 600;
}

.fancy-input-field {
  border: 3px solid white;
  color: white;
  outline: white;
  background-color: transparent;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  width: 200px;
  text-align: center;

  &::placeholder {
    color: white;
    opacity: 0.2;
    font-weight: light;
  }
  & + label {
    position: absolute;
    transition: all 0.3s ease-in-out;
    opacity: 0;

    top: -30px;
    left: 50%;
    color: white;
    padding: 0 5px;
    translate: -50% 10px;
    text-wrap: nowrap;
  }

  &:focus {
    color: white;
    outline: black;

    &::placeholder {
      visibility: hidden;
    }

    & + label {
      opacity: 1;
      translate: -50% 0px;
    }
  }
}

.fade-text {
  &-1,
  &-2,
  &-3 {
    transition: all 1s ease-in-out;
    display: inline-block;
    opacity: 0;
    font-size: 120px;

    &.isMounted {
      opacity: 1;
      translate: 0px 0px;
    }
  }

  &-1 {
    translate: 0px -100px;
    margin-right: 40px;
  }

  &-3 {
    translate: 0px 100px;
    margin-left: 40px;
    transition-delay: 500ms;
  }
}

.home-title {
  margin-top: -250px;

  p.subtitle {
    opacity: 0;
    font-size: 20px;
    transition: all 0.35s ease-in-out;

    &.isMounted {
      opacity: 1;
      translate: 0px 0px;
      transition-delay: 1200ms;
    }
  }
}

@media (max-width: 1000px) {
  .home-title {
    margin-top: -200px;
    span {
      font-size: 100px;
    }
  }
  .fade-text {
    &-1 {
      margin-right: 20px;
    }

    &-3 {
      margin-left: 20px;
    }
  }
}

@media (max-width: 600px) {
  .home-title {
    margin-top: -150px;
    span {
      font-size: 60px;
    }
  }
  .fade-text {
    &-1 {
      margin-right: 10px;
    }

    &-3 {
      margin-left: 10px;
    }
  }
}

.home-badge {
  min-width: 338px;
  height: 85px;
  border-radius: 100px;
  padding-left: 80px;
  padding-right: 80px;
  font-size: 18px;
  opacity: 0;
  transition: all 1s ease-in-out;

  .divider {
    margin-left: 30px;
    margin-right: 30px;
  }

  &.isMounted {
    opacity: 1;
    translate: 0px 0px;
    transition-delay: 1000ms;
  }
}

.our-story-button {
  opacity: 0;
  transition: "opacity" 1s ease-in-out;

  &.isMounted {
    opacity: 1;
    translate: 0px 0px;
    transition-delay: "opacity" 1000ms;
  }
}

@media (max-width: 600px) {
  .home-badge {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 14px;

    .divider {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
}

.btn-primary {
  outline: none;
  border: none;
  padding: 5px 30px;
  background-color: colors.$brand-primary;
  display: flex;
  align-items: center;
  color: white;
  transition: all 0.15s ease-in-out;

  span {
    color: white;
    font-weight: 200;
    letter-spacing: 10px;
    margin-right: -5px;
  }

  &:hover {
    background-color: darken($color: colors.$brand-primary, $amount: 5);
  }

  &:disabled {
    background-color: lighten($color: colors.$brand-primary, $amount: 25);

    &:hover {
      background-color: lighten($color: colors.$brand-primary, $amount: 25);
    }
  }
}

.btn-secondary {
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  border-radius: 100px;
}

.btn-tertiary {
  padding: 15px;
  padding-left: 40px;
  padding-right: 40px;
  color: white;
  background: transparent;
  border: 1px solid white;
  border-radius: 100px;
  transition: all 0.15s ease-in-out;

  &:hover {
    background-color: colors.$brand-secondary;
    color: #222;
  }
}

.fade-button {
  transition: all 1.3s ease-in-out;
  display: flex;
  opacity: 0;
  translate: 0px 200px;
  transition-delay: 1000ms;

  &.isMounted {
    opacity: 1;
    translate: 0px 0px;
  }
}

header {
  position: absolute;
  z-index: 1;
  transition: all 0.3s ease-in-out;
  top: 0px;
  width: 100vw;
  background-color: colors.$brand-secondary;

  &.hidden {
    top: -120px;
  }

  &.transparent {
    background-color: transparent;
  }
}

.rsvp-input-field {
  border: 1px solid #222;
  color: #222;
  outline: #222;
  // background-color: colors.$brand-secondary;
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
  width: 100%;

  &::placeholder {
    color: #222;
    opacity: 0.4;
    font-weight: light;
  }
  & + label {
    position: absolute;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    top: -25px;
    left: 0px;
    color: #222;
    padding: 0 5px;
    translate: -0px 10px;
    text-wrap: nowrap;
    font-size: 18px;
    font-weight: bold;
  }

  &:focus,
  &.has-value {
    color: colors.$brand-primary;
    outline: black;

    & + label {
      opacity: 1;
      translate: -0px 0px;
    }
  }
}

.rings-section {
  display: flex;
  font-size: 18px;
  align-items: center;
  padding: 40px;
  height: calc(50% + 200px);
  padding-bottom: 180px;

  .left-content {
    margin-right: 100px;
    width: 40%;
  }
  .right-content {
    margin-left: 100px;
    width: 40%;
  }
}

@media (max-width: 950px) {
  .rings-section {
    padding-top: 80px;
    flex-direction: column;
    height: 100%;

    .rings-container {
      padding: 20px;
      height: 200px;
    }

    .left-content {
      text-align: center;
      margin-right: 0px;
      width: 80%;
    }
    .right-content {
      margin-top: 40px;
      text-align: center;
      margin-left: 0px;
      width: 80%;
    }
  }
}

@media (max-width: 750px) {
  .rings-section {
    height: 85%;
  }
}

@media (max-width: 610px) {
  .rings-section {
    .left-content {
      width: 100%;
    }
    .right-content {
      width: 100%;
    }
  }
}

@media (max-width: 500px) {
  .rings-section {
    height: 100%;

    .left-content {
      width: 100%;
    }
    .right-content {
      width: 100%;
    }
  }
}

@media (max-width: 395px) {
  .rings-section {
    height: 110%;
  }
}

.section-reception {
  padding-bottom: 120px;

  .reception-container {
    display: flex;
    align-items: start;
    justify-content: center;
    margin-top: -160px;
    width: 100%;

    .img-container {
      display: flex;
      flex-direction: column;
      width: 40%;
      justify-content: center;

      img {
        width: 100%;
      }

      .img-texts {
        padding: 10px;
        padding-top: 20px;
        text-align: center;
      }

      h2 {
        font-size: 50px;
      }

      p {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 750px) {
  .section-reception {
    .reception-container {
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-top: -80px;

      .img-container {
        width: 40%;

        img {
          width: 100%;
        }

        .img-texts {
          padding: 15px;
        }

        h2 {
          font-size: 50px;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .section-reception {
    .reception-container {
      .img-container {
        width: 80%;
      }
    }
  }
}

.section-timeline {
  display: flex;
  flex-direction: column;
  align-items: center;

  .timeline-container {
    max-width: 750px;
  }
}

.timeline-item {
  padding-left: 1.5rem;
}

footer {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;
  padding: 40px;
}

@media (max-width: 750px) {
  footer {
    flex-direction: column;

    h4 {
      margin-top: 40px;
      text-align: center;
    }
  }
}

.section-colors {
  padding: 40px;
  padding-bottom: 100px;

  .section-colors-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      font-size: 40px;
      text-align: center;
      margin-bottom: 30px;
    }
    padding-top: 40px;
    border-top: 1px solid #dadada;

    .colors-container {
      max-width: 400px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }

    .wedding-color-circle {
      height: 70px;
      width: 70px;
      border-radius: 100%;
      margin: 10px;
    }
  }
}

.our-story-section-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 100px;
  padding-bottom: 24px;
  padding-left: 48px;
  padding-right: 48px;

  h3 {
    font-size: 40px;
    text-align: center;
  }
}

@media (max-width: 750px) {
  .our-story-section-1 {
    padding-top: 100px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;

    h3 {
      font-size: 30px;
      text-align: center;
    }
  }
}

@mixin flex-center() {
  margin-right: 32px;
}

.our-story-section {
  .img-container {
    display: flex;
    align-items: center;
    justify-content: center;

    &.left-image {
      margin-right: 30px;
    }

    &.right-image {
      margin-left: 30px;
    }

    &.bottom-image {
      margin-top: 30px;

      img {
        width: 80vw;
      }
    }
  }
  .text-container {
    p {
      font-size: 22px;
    }
  }
}

@media (max-width: 800px) {
  .our-story-section {
    .container {
      flex-direction: column;

      .img-container {
        margin-bottom: 30px;

        &.left-image {
          margin-right: 0px;
          margin-bottom: 30px;
        }

        &.right-image {
          margin-left: 0px;
          margin-top: 30px;
        }

        &.bottom-image {
          margin-top: 30px;

          img {
            width: calc(100vw - 2rem);
          }
        }
      }
      .text-container {
        p {
          font-size: 19px;
        }
      }
    }
  }
}

.link-card {
  cursor: pointer;
  width: 250px;
  height: 250px;
  border-radius: 20%;
  -webkit-box-shadow: 7px 8px 9px -7px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 7px 8px 9px -7px rgba(0, 0, 0, 0.75);
  box-shadow: 7px 8px 9px -7px rgba(0, 0, 0, 0.75);
}

// .section-links-wrapper {
//   .link-card {
//     margin-left: 80px;
//   }
// }

@media (max-width: 880px) {
  .link-card {
    width: 180px;
    height: 180px;
  }

  // .section-links-wrapper {
  //   .link-card:not(:first-child) {
  //     margin-left: 40px;
  //   }
  // }
}

.section-registry-1 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 120px;
  padding-bottom: 48px;
  padding-left: 48px;
  padding-right: 48px;
}
