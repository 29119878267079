$brand-primary: rgb(172, 94, 35);
$brand-secondary: rgb(252, 248, 235);
$normal-font-color: rgb(51, 45, 43);

.text-normal {
  color: $normal-font-color;
}

.text-brand-primary {
  color: $brand-primary;
}
.bg-brand-primary {
  background-color: $brand-primary;
}
.bg-brand-secondary {
  background-color: $brand-secondary;
}
.text-brand-secondary {
  color: $brand-secondary;
}
